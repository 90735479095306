import React from 'react';
import ReactDOM from 'react-dom';
import {Route} from "react-router-dom"
import { BrowserRouter as Router } from "react-router-dom"
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import './assets/css/style.css'
import './assets/css/preloader.css'
import './assets/css/line-awesome.min.css'

ReactDOM.render((
    <Router>
        <div>
            <Route exact path="/" component={App} />
            <Route exact path="/:language" component={App} />
        </div>
    </Router>),
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

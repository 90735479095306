import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import './contact.css';
import $ from "jquery"
import ScrollAnimation from "react-animate-on-scroll";

class Contact extends Component {

    componentDidMount() {
        $('#contact-form-submit').click(function (e) {

            let $error = 0;

            const error = $('#contact-form .error');
            const success = $('#contact-form .success');

            const field_name = $("#contact-form-name");
            const field_email = $("#contact-form-email");
            const field_subject = $("#contact-form-subject");
            const field_message = $("#contact-form-message");
            // Anti spam field
            const field_website = $("#contact-form-website");

            const name = field_name.val();
            const email = field_email.val();
            const subject = field_subject.val();
            const message = field_message.val();
            const website = field_website.val();

            const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

            success.fadeOut(1000);

            if (website !== "") {
                return
            }

            if (name === "" || email === "" || subject === "" || message === "" || !regex.test(email)) {
                error.fadeIn(1000);
                $error = 1;

            } else {
                error.fadeOut(1000);
            }

            const dataString = 'name=' + name + '&email=' + email + '&subject=' + subject + '&message=' + message;

            if ($error === 0) {
                $.ajax({
                    type: "POST",
                    url: "http://www.steffanlildholdt.dk/php/send-mail.php",
                    data: dataString,
                    success: function (data) {
                        error.fadeOut(1000);
                        success.fadeIn(1000);

                        // Reset form fields
                        field_name.val("");
                        field_email.val("");
                        field_subject.val("");
                        field_message.val("");
                    }
                });
                return false;
            }
            e.preventDefault();
        });
    }

    render() {
        const {t} = this.props;
        return (
            <section id="contact">
                <div className="container text-center">
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <h1>{t('contact.headline')}</h1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true} offset="500">
                        <div className="row contacts text-center">
                            <div className="col-sm-12 text-center">
                                    <div className="item mail">
                                        <a href = "mailto: lineaggerbo@gmail.com.com">
                                            <i class="lar la-envelope"></i>
                                        </a>
                                    </div>
                                    <li className="item linkedin">
                                        <a href="https://www.linkedin.com/in/line-aggerbo-johansen-a567804a/">
                                            <i class="lab la-linkedin"></i>
                                        </a>
                                    </li>
                                    <li className="item facebook">
                                        <a href="https://www.facebook.com/line.aggerbo">
                                            <i class="lab la-facebook"></i>
                                        </a>
                                    </li>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </section>
        )
    }
}

Contact.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Contact);

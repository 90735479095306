import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import {Parallax} from 'react-parallax';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonials.css';

class Testimonials extends Component {

    render() {
        const {t} = this.props;
        const testimonials = [
            {
                "company": "Beumer Group",
                "author": "Henry Ford",
                "text": t('testimonials.henryford')
            },
        ];

        const slider = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const parallax = {
            blur:0,
            bgImage:require('./teamwork.jpg'),
            strength:300
        };

        return (
            <Parallax {...parallax}>
                <section id="testimonials">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                                    <Slider {...slider}>
                                        {/* {testimonials.map((item, i) =>
                                            <div className="item" key={i}>
                                                <p>"{item.text}"</p>
                                                <p className="person">{item.author}</p>
                                            </div>
                                        )} */}
                                    </Slider>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
            </Parallax>
        )
    }
}

Testimonials.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Testimonials);

export const Keywords = {
    ADO: "Azure DevOps",
    AZURE: "Azure",
    C: "C",
    CSHARP: "C#",
    CSS: "CSS",
    CQRS: "CQRS",
    DCE: "Dynamics Costumer Engagemnet",
    DDD: "DDD",
    DFS: "Dynamics Field Service",
    DOCKER: "Docker",
    D365: "Dynamics 365",
    EVENTSOURCING: "Event sourcing",
    GERRIT: "Gerrit",
    GIT: "Git",
    GRPC: "GRPC",
    HTML: "HTML",
    JAVA: "Java",
    JAVASCRIPT: "Javascript",
    JENKINS: "Jenkins",
    JIRA: "JIRA",
    MICROSERVICES: "Microservices",
    MSSQL: "MSSQL",
    PM: "Project management",
    PYTHON: "Python",
    RABBITMQ: "RabbitMQ",
    REST: "REST",
    SAFE: "SaFE",
    SCRUM: "Scrum",
    SOAP: "SOAP",
    TENSORFLOW: "TensorFlow",
    TL: "Team lead",
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import './resume.css';
import {Keywords} from "./keywords";

class Experience extends Component {

    render() {
        const {t} = this.props;
        const experience = [
            {
                "title": t('resume.experience.netcompany-manager.title'),
                "company": t('resume.experience.netcompany-manager.company'),
                "start": 2021,
                "end": null,
                "description": t('resume.experience.netcompany-manager.description'),
                "technologies": [
                    Keywords.CSHARP,
                ]
            },
            {
                "title": t('resume.experience.netcompany-senior.title'),
                "company": t('resume.experience.netcompany-senior.company'),
                "start": 2019,
                "end": 2020,
                "description": t('resume.experience.netcompany-senior.description'),
                "technologies": [
                    Keywords.JAVA,
                ]
            },
            {
                "title": t('resume.experience.netcompany-consultant.title'),
                "company": t('resume.experience.netcompany-consultant.company'),
                "start": 2016,
                "end": 2018,
                "description": t('resume.experience.netcompany-consultant.description'),
                "technologies": [
                    Keywords.JAVA,
                ]
            },
            {
                "title": t('resume.experience.easycount.title'),
                "company": t('resume.experience.easycount.company'),
                "start": 2014,
                "end": 2012,
                "description": t('resume.experience.easycount.description'),
                "technologies": [
                    Keywords.JAVA,
                ]
            }
        ];


        return (
                <ul className="items">
                    {experience.map((item, i) =>
                        <li className="item" key={i}>
                            <div className="box animated" data-animationduration="1" data-animationtype="fadeInRightBig">
                                <div className="top">
                                    <p className="shorter bolder title">{item.title}</p>
                                    <p className="shorter highlight company">{item.company}</p>
                                    <p className="period">{item.start}</p>
                                </div>
                                <div className="bottom">
                                    <p>{item.description}</p>
                                    {item.technologies != null &&
                                    <div className="technologies">
                                        <ul>
                                            {item.technologies?.map((technology, i) => <li key={i}>{technology}</li>)}
                                        </ul>
                                    </div>
                                }
                                </div>
                                <i className="arrow fa fa-angle-down"/>
                            </div>
                            <div className="bullet animated" data-animationduration="1" data-animationtype="fadeIn"/>
                        </li>
                    )}
                </ul>
        )
    }
}

Experience.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Experience);

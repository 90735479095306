import React, {Component} from 'react';
import {setTranslations, setDefaultLanguage, setLanguage} from 'react-switch-lang';
import Home from './sections/Home/Home'
import Profile from './sections/Profile/Profile'
import Testimonials from './sections/Testimonials/Testimonials'
import Resume from "./sections/Resume/Resume";
import Contact from "./sections/Contact/Contact";
import en from "./assets/translations/en.json"
import dk from "./assets/translations/dk.json"
import $ from "jquery";

setTranslations({en, dk});
setDefaultLanguage('dk');

class App extends Component {

    constructor(props) {
        super(props);
        const language = this.props.match.params.language;
        if (language === 'dk' || language === 'en') {
            setLanguage(language);
        }
    }

    componentDidMount() {
        // Fade out the preloader
        const loading = "#loading";
        const preloader = "#loading .preloader";
        $(loading).delay(1000).fadeOut('slow');
        $(preloader).delay(500).fadeOut('slow');
    }

    render() {
        return (
            <div className="App">
                <div id="loading">
                    <div className="preloader">
                        <div className="lds-ellipsis"><div/><div/><div/><div/></div>
                    </div>
                </div>
                <Home/>
                <Profile/>
                <Testimonials/>
                <Resume/>
                <Contact/>
            </div>
        );
    }
}

export default App;

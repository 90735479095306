import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'react-switch-lang';
import $ from "jquery"
import './resume.css';
import Experience from "./Experience";
import Education from "./Education";
import Certificates from "./Certificates";
import ScrollAnimation from "react-animate-on-scroll";

class Resume extends Component {

    componentDidMount() {
        const navItem = "#resume .nav-item";
        const activePane = "#resume .tab-content .active";
        const activeNavItem = "#resume .nav .active";
        $(navItem).click(function () {
            const tab = $(this).attr("data-tab");
            $(activePane).toggleClass("active");
            $("#" + tab).toggleClass("active");
            $(activeNavItem).toggleClass("active");
            $(this).toggleClass("active")
        });

        const infoClass = '#resume .box';
        const bottomClass = ".bottom";
        const bulletClass = ".bullet";
        $(infoClass).click(function () {
            // Find height of expanded info box
            const bottom = $(this).find(bottomClass);
            const bullet = $(this).parent().find(bulletClass);
            bullet.toggleClass("bullet-active");

            // Toggle text
            bottom.animate({height: "toggle", opacity: "toggle"}, "normal");

            // Rotate arrow
            const arrowClass = ".arrow";
            const arrow = $(this).find(arrowClass);
            arrow.toggleClass('rotated');
        });
    }

    render() {

        const {t} = this.props;
        return (
            <section id="resume">
                <div className="container">
                    <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
                        <div className="row">
                            <div className="col-sm-12 text-center">

                                <ul className="nav">
                                    <li className="nav-item active" data-tab="experience">
                                        <i className="las la-suitcase" />
                                        <span>{t('resume.experience.nav')}</span>
                                    </li>
                                    <li className="nav-item" data-tab="education">
                                        <i className="las la-book" />
                                        <span>{t('resume.education.nav')}</span>
                                    </li>
                                    <li className="nav-item" data-tab="certificates">
                                        <i className="las la-award" />
                                        <span>{t('resume.certificates.nav')}</span>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="experience">
                                        <Experience/>
                                    </div>
                                    <div className="tab-pane" id="education">
                                        <Education/>
                                    </div>
                                    <div className="tab-pane" id="certificates">
                                        <Certificates/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </section>
        )
    }
}

Resume.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate(Resume);
